import './index.css';

function Viewport({ children }) {

    return (
        <div id="app">
            {children}
        </div>
    )
}

function AppBar({ children, bordered }) {

    const getClass = () => {
        const classNames = []
        if (bordered === true) classNames.push('bordered');
        return classNames.join(' ');
    };

    return (
        <div id="app-bar" className={getClass()}>
            {children}
        </div>
    )
}

function AppSidebar({ children, bordered }) {

    const getClass = () => {
        const classNames = []
        if (bordered === true) classNames.push('bordered');
        return classNames.join(' ');
    };

    return (
        <div id="app-sidebar" className={getClass()}>
            {children}
        </div>
    )
}

function AppContent({ children }) {

    return (
        <div id="app-content">
            {children}
        </div>
    )
}

function ContentSidebar({ children, bordered, slim }) {

    const getClass = () => {
        const classNames = ['content-sidebar']
        if (bordered === true) classNames.push('bordered');
        if (slim === true) classNames.push('slim');
        return classNames.join(' ');
    };

    return (
        <div className={getClass()}>
            {children}
        </div>
    )
}

function ContentMenu({ children, bordered }) {

    const getClass = () => {
        const classNames = ['content-menu']
        if (bordered === true) classNames.push('bordered');
        return classNames.join(' ');
    };

    return (
        <div className={getClass()}>
            {children}
        </div>
    )
}

function ContentContainer({ children, style, responsive, padded, scrollable }) {

    const getClass = () => {
        const classNames = ['content-container']
        if (scrollable === true) classNames.push('scrollable');
        if (padded === true) classNames.push('padded');
        if (responsive === true) classNames.push('responsive');
        return classNames.join(' ');
    };


    return (
        <div className={getClass()} style={style}>
            {children}
        </div>
    )
}


export { Viewport, AppBar, AppSidebar, AppContent, ContentSidebar, ContentMenu, ContentContainer }
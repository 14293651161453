import { GraphQLClient } from 'graphql-request'
import transformStrapiV4Response from './transformStrapiV4Response'


class ApiRequestError extends Error {
    constructor(code, ...params) {
        super(...params)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiRequestError)
        }

        this.name = 'ApiRequestError'
        this.code = code
    }
}


async function apiRequest(query, variables) {
    const endpoint = process.env.REACT_APP_OOC_GRAPHQL_ENDPOINT;

    const authToken = window.localStorage.getItem("authToken");
    const authHeader = authToken ?
        {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        }
        : undefined;

    const graphQLClient = new GraphQLClient(endpoint, authHeader);

    try {
        const data = await graphQLClient.request(query, variables);
        transformStrapiV4Response(data);

        return data;
    } catch (error) {
        let code = 500;
        let message = "Unknown Error";

        if (!!error.response) {
            code = error.response.status;
            if (!!error.response.error) {
                message = `${error.response.error.name}: ${error.response.error.message}`;
            }
            else if (!!error.response.errors) {
                const payload = error.response.errors[0];

                if (/forbidden/i.test(payload.extensions?.code)) code = 403;

                if (!!payload.extensions.exception) {
                    message = `${payload.extensions.code}: ${payload.message}`;
                }
                else {
                    message = `${payload.extensions.error.name}: ${payload.extensions.error.message}`;
                }
            }
        }

        if (code === 401) {
            window.localStorage.removeItem("authToken");
            window.location.reload();
        } else {
            throw new ApiRequestError(code, message);
        }
    }

}

export default apiRequest;
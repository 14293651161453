import { useAtom } from "jotai";
import { useEffect } from "react";

import userAtom from "../data/atoms/user";
import userRepository from "../data/user.repository";

/*
* React Hook to share common user related data and functions
*/
function useUserSession() {
    const [user, setUser] = useAtom(userAtom);

    const loadUserProfile = async () => {
        if (user === null) {
            const userProfile = await userRepository.getProfile();
            setUser(userProfile);
        }
    }

    useEffect(() => {
        const userSession = window.localStorage.getItem("authToken");
        if (userSession) {
            loadUserProfile();
        }
        else {
            setUser(null);
        }
    },
        //eslint-disable-next-line
        []
    );

    const saveUserSession = (sessionInfo, useSession = true) => {
        if (sessionInfo !== null) {
            window.localStorage.setItem("authToken", sessionInfo.jwt);
            if (useSession === true) setUser(sessionInfo.user);
        }
    };

    return {
        profile: user,
        /*
        * @return bool
        */
        isEmailRegistered: async (email) => {
            try {
                return await userRepository.isEmailRegistered(email);
            } catch {
                return false;
            }
        },
        /*
        * @return bool
        */
        login: async (email, password) => {
            try {
                const result = await userRepository.login(email, password);
                saveUserSession(result, false);
                await loadUserProfile();
                return true;
            } catch {
                return false;
            }
        },
        /*
        * @return bool
        */
        connect: async (provider, accessToken) => {
            try {
                const result = await userRepository.connect(provider, accessToken);
                if(!result.error){
                  saveUserSession(result, false);
                  await loadUserProfile();
                  return true;
                }
                else {
                  return {error: result.error.message};
                }
            } catch {
                return  {error: "Unknown error is occured!"};
            }
        },
        /*
        * @return bool
        */
        forgotPassword: async (email) => {
            try {
                const result = await userRepository.forgotPassword(email);

                return result;
            } catch {
                return false;
            }
        }, /*
        * @return bool
        */
        resetPassword: async (token, password) => {
            try {
                const result = await userRepository.resetPassword(token, password);
                saveUserSession(result, false);
                return true;
            } catch {
                return false;
            }
        },
        logout: async () => {
            window.localStorage.removeItem("authToken");
            setUser(null);
        },
    }
}

export default useUserSession;

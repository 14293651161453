import { atom } from 'jotai';

/**
 * 
 * @type object | null
 *   
 * {
 *   "form": {},
 *   "originalData": {},
 *   "isChanged": false,
 * }
 *
 */
const activeFormAtom = atom(null);

export default activeFormAtom;
import React from 'react';
import { Result, Collapse, Row, Col } from 'antd'


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Result
                    status={[500, 404, 403].includes(this.state.error?.code) ? this.state.error?.code : "error"}
                    title={this.state.error.message || "Sorry, something went wrong!"}
                    extra={
                        process.env.NODE_ENV === "development" && this.state.errorInfo &&
                        <Row>
                            <Col xl={{ span: 12, offset: 6 }} lg={{ span: 16, offset: 4 }}>
                                <Collapse ghost>
                                    <Collapse.Panel header="Error Details">
                                        <div style={{ textAlign: "left", whiteSpace: 'pre-wrap' }}>
                                            <pre>
                                                {this.state.errorInfo.componentStack}
                                            </pre>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    }
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
import React, { Suspense, lazy } from 'react';

import { Switch, Route } from "react-router-dom";
import { Viewport, AppBar, AppContent, ContentContainer } from '../components/layout';
import Header from '../components/header';
import Fallback from '../components/suspense-fallback';
import ErrorBoundary from '../components/error-boundary';
import { Row, Col } from "antd";


import './App.less';
import useUserSession from '../hooks/useUserSession';
import { BCcampusLogo, BrandLogo } from '../components/logo';

const Dashboard = lazy(() => import('./dashboard'));
const Courses = lazy(() => import('./courses'));
const Textbooks = lazy(() => import('./textbooks'));
const RestrictedMaterialRequests = lazy(() => import('./restricted-material-requests'));
const Login = lazy(() => import('./login'));
const Connect = lazy(() => import('./connect'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const ResetPassword = lazy(() => import('./reset-password'));

function App() {

  const { profile } = useUserSession();

  return (
    <Viewport>
      {
        profile
          ?
          <>
            <AppBar bordered>
              <Header />
            </AppBar>
            <AppContent>
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/dashboard/:id" component={Dashboard} />
                    <Route path="/courses" component={Courses} />
                    <Route path="/textbooks" component={Textbooks} />
                    <Route path="/restricted-material-requests" component={RestrictedMaterialRequests} />
                  </Switch>
                </Suspense>
              </ErrorBoundary>
            </AppContent>
          </>
          :
          <ContentContainer style={{ backgroundColor: "#f8f7fa" }}>
            <ErrorBoundary>
              <Suspense fallback={<Fallback />}>
                <Row justify="space-around" align="middle" style={{ height: "70%" }}>
                  <Col xs={22} sm={16} md={12} xl={8} xxl={6}>
                    <Row gutter={12}>
                      <Col span={24} style={{ textAlign: "center", padding: "2em" }}>
                        <BrandLogo style={{ width: "100%" }} />
                      </Col>
                    </Row>
                    <Row gutter={12} style={{ borderRadius: "8px", border: "var(--app-border-style)", padding: "2em" }}>
                      <Col span={24}>
                        <Switch>
                          <Route path="/reset-password/:token" component={ResetPassword} />
                          <Route path="/forgot-password" component={ForgotPassword} />
                          <Route path="/connect/:provider/redirect" component={Connect} />
                          <Route path="/" component={Login} />
                        </Switch>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-around" align="bottom" style={{ height: "30%" }}>
                  <Col xs={12} sm={8} md={6} xl={4} xxl={2} style={{ textAlign: "center", padding: "2em" }}>
                    <BCcampusLogo style={{ width: "100%" }} />
                  </Col>
                </Row>
              </Suspense>
            </ErrorBoundary>
          </ContentContainer>
      }
    </Viewport>
  );
}

export default App;

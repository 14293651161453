import useUserSession from '../../hooks/useUserSession';
import NavAction from '../NavAction';
import './index.css';

function TopMenu({ items, direction }) {
    const { profile } = useUserSession();

    const getClass = () => {
        const classNames = ['bccampus-navigation']
        classNames.push((direction === "vertical") ? 'vertical' : 'horizontal');

        return classNames.join(' ');
    };

    return (
        <div className={getClass()}>
            {items &&
                Object.entries(items).map(item => {
                    if (Array.isArray(item[1].roles) && profile.role && !item[1].roles.includes(profile.role.name))
                        return null;

                    return (
                        <NavAction componentType="navigation"
                            id={`bccampus-navigation-item-${item[0]}`}
                            className="bccampus-navigation-item"
                            key={item[1].to}
                            to={item[1].to}
                            exact={item[1].exactMatch}
                        >
                            {item[1].icon}
                            {item[1].label}
                        </NavAction>
                    );
                })
            }
        </div>
    )
}

export default TopMenu;
import { gql } from 'graphql-request'
import apiRequest from '../utils/bccampusGraphQLRequest'
import axios from 'axios';

const profileFields = `{
  id
  username
  email
  role {
    id
    name
  }
}`;

const isEmailRegistered = async (email) => {
  const variables = {
    email,
  };

  const result = await apiRequest(gql`query CountUsersByEmail($email: String!) {
      usersCount(filters: { email: {eq: $email} })
    }`, variables);

  return result.usersCount === 1;
}

const getProfile = async () => {
  const result = await apiRequest(gql`query { me ${profileFields} }`);
  return result?.me;
}

const login = async (email, password) => {
  const variables = {
    email,
    password,
  };

  const result = await apiRequest(gql`mutation Login($email: String!, $password: String!) {
      login(input:{identifier: $email, password: $password}){
        jwt
        user ${profileFields}
      }
    }`, variables);


  return result?.login;
}

const connect = async (provider, accessToken) => {
  try {
    const response = await axios.request({
      method: "GET",
      url: `${process.env.REACT_APP_OOC_REST_ENDPOINT}/auth/${provider}/callback`,
      params: { access_token: accessToken }
    });
    return response.data;
  }
  catch (err) {
    return { error: err.response.data.error };
  }
}

const forgotPassword = async (email) => {
  const variables = {
    email
  };

  const result = await apiRequest(gql`mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email){
        ok
      }
    }`, variables);

  return result.forgotPassword.ok;
}

const resetPassword = async (token, password) => {
  const variables = {
    token,
    password,
  };

  const result = await apiRequest(gql`mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(code: $token, password: $password, passwordConfirmation: $password){
        jwt
        user ${profileFields}
      }
    }`, variables);


  return result.resetPassword;
}

const userRepository = { isEmailRegistered, getProfile, login, connect, forgotPassword, resetPassword };

export { isEmailRegistered, getProfile, login, connect, forgotPassword, resetPassword }
export default userRepository

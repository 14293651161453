import React from 'react';
import { Provider } from 'jotai';
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";

import App from './views/App';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Provider>
    <Router>
      <App />
    </Router>
  </Provider>
);

import { ContentContainer } from '../layout';
import TopMenu from '../menu';
import Horizontal from '../horizontal';
import UserMenuItem from '../user-menu-item';

import { BrandLogo } from '../logo';
import { Typography, Grid } from 'antd';

function Header() {
  const screens = Grid.useBreakpoint();

  const menuItems = {
    'courses': {
      label: 'Course Packs',
      to: '/courses',
      roles: ['Admin', 'Authenticated']
    },
    'textbooks': {
      label: 'Textbooks',
      to: '/textbooks',
      roles: ['Admin', 'Authenticated']
    },
    'restricted': {
      label: 'Restricted Material Requests',
      to: '/restricted-material-requests',
      roles: ['Admin', 'Support']
    }
  };

  return (
    <ContentContainer padded>
      <Horizontal tight>
        <Horizontal.Part style={!screens.lg ? { width: "30px", overflow: "hidden" } : undefined}>
          <BrandLogo style={{ height: "calc(var(--app-bar-height) - 3em" }} />
        </Horizontal.Part>
        <div>
          <Typography.Text strong
            style={{
              borderLeft: "var(--app-border-style)",
              marginLeft: "1em",
              padding: "0.5em 1em",
              fontSize: "1.25em"
            }}
          >
            Collection Manager
          </Typography.Text>
        </div>
        <Horizontal.Spacer />
        <TopMenu items={menuItems} />
        <Horizontal.Part centered>
          <UserMenuItem />
        </Horizontal.Part>
      </Horizontal>
    </ContentContainer>
  )
}

export default Header;

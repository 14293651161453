import { NavLink, Link } from "react-router-dom";
import { Button } from "antd";

import isDeepEqual from "lodash.isequal";
import activeFormAtom from "../../data/atoms/active-form";
import { Modal } from "antd";
import { useAtomValue } from "jotai/utils";


function useNavValidation() {
    const activeForm = useAtomValue(activeFormAtom);
    return (clickEvent, onClick) => {
        if (activeForm) {
            if (!isDeepEqual(activeForm.form.getFieldsValue(), activeForm.originalData)) {
                const targetNavLink = clickEvent.currentTarget;

                clickEvent.preventDefault();
                clickEvent.stopPropagation();

                Modal.confirm({
                    title: "Unsaved Changes",
                    content: "Are you sure you want to leave this page? Your changes will be lost.",
                    centered: true,
                    onOk: () => {
                        activeForm.form.setFieldsValue(activeForm.originalData);

                        setTimeout(() => {
                            if (onClick) {
                                onClick(clickEvent);
                            } else {
                                targetNavLink.click();
                            }
                        }, 250)
                    },
                    okText: "Leave and discard",
                    okType: "danger",
                    cancelText: "Cancel",
                });
            } else if (onClick) {
                onClick(clickEvent);
            }
        }
        else if (onClick) {
            onClick(clickEvent);
        }
    }
}

function NavAction({ componentType, children, onClick, ...restProps }) {
    const handleClick = useNavValidation();

    switch (componentType) {
        case "navigation":
            return (
                <NavLink onClick={handleClick} {...restProps}>
                    {children}
                </NavLink>
            )

        case "button":
            return (
                <Button onClick={(e) => handleClick(e, onClick)} {...restProps}>
                    {children}
                </Button>
            )

        default:
            return (
                <Link onClick={handleClick} {...restProps}>
                    {children}
                </Link>
            )
    }
}

export { useNavValidation, NavAction }
export default NavAction;
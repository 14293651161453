import './index.css';

function Horizontal({ children, tight, centered, as, className, style, ...restProps }) {

    const getClass = () => {
        const classNames = ['list-item']
        if (className) classNames.push(className);
        if (tight === true) classNames.push('tight');
        if (centered === true) classNames.push('centered');
        return classNames.join(' ');
    };

    return (
        as !== undefined
            ?
            as({ ...restProps, className: getClass(), style: style, children: children })
            :
            <div{...{ ...restProps, className: getClass(), style: style }}>
                {children}
            </div>
    )
}

Horizontal.Spacer = function () {
    return (
        <div className='spacer'></div>
    )
}

Horizontal.Part = function ({ children, size, stretched, double, centered, truncateText, style }) {

    const getClass = () => {
        const classNames = []
        if (stretched === true) classNames.push('stretched');
        if (double === true) classNames.push('double');
        if (centered === true) classNames.push('centered');
        if (truncateText === true) classNames.push('truncate-text');
        if (size !== null) {
            classNames.push(size);
        }
        return classNames.join(' ');
    };

    return (
        <div className={getClass()} style={style}>
            {children}
        </div>
    )
}

export default Horizontal;

import { Popover, Button } from "antd";
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import useUserSession from "../../hooks/useUserSession";
import NavAction from "../NavAction";

function UserMenuItem() {
    const { profile, logout } = useUserSession();

    return (

        <Popover
            title={profile.email}
            content={
                <div>
                    <NavAction componentType="button" type="default" block icon={<LogoutOutlined />} onClick={logout}>
                        Logout
                    </NavAction>
                </ div>
            }
            trigger="click"
            placement="topRight"
        >
            <Button shape="circle" icon={<UserOutlined />} />
        </Popover>
    )
}

export default UserMenuItem;
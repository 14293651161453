import { atom } from 'jotai';

/*
* @type object | null
*   
* {
*   "jwt": "...",
*   "id": "9999",
*   "email": "...",
*   "username": "...",
*   "role": {
*     "id": "9999",
*     "name": "Authenticated"
*   }
* }
*
*/
const userAtom = atom(null);

export default userAtom;